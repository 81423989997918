import { useRouter } from "vue-router";
export default function () {
    const router = useRouter();
    //前往首页
    const handleHomeRouter = function () {
        router.push({ name: "home" });
    };
    //前往教练风采
    const handleAboutCoachRouter = function () {
        window.open("https://docs.qq.com/slide/DQUtuZ3ZhU2theU5l");
    };
    //前往用户故事
    const handleUserStoryRouter = function () {
        window.open("https://docs.qq.com/slide/DTWVRUW9vSkp4V1N3");
    };
    //前往企业团购
    const handleEnterpriseBuyRouter = function () {
        window.open("https://docs.qq.com/slide/DV0VYQldFVVB3aVdn");
    };
    //前往关于我们
    const handleAboutUsRouter = function () {
        //这里注释掉是因为需求暂定要用指定的页面跳转
        //router.push({ name: "aboutUs" });
        window.open("https://docs.qq.com/slide/DV0x0SGFDRVZQaUxL");
    };
    //前往观看完整视频
    const handleVideoRouter = function () {
        router.push({ name: "video" });
    };
    //前往加入我们
    const handleJoinUsRouter = function () {
        //这里注释掉是因为需求暂定要用指定的页面跳转
        //router.push({name:"joinUs"})
        window.open("https://docs.qq.com/slide/DRGZFZndBR2lWUHRE");
    }
    return {
        handleHomeRouter,
        handleAboutCoachRouter,
        handleUserStoryRouter,
        handleEnterpriseBuyRouter,
        handleAboutUsRouter,
        handleVideoRouter,
        handleJoinUsRouter
    }
}