import { createI18n } from 'vue-i18n'


const i18n = createI18n({
    locale: 'zh-CN', // 语言标识
    messages: {
        'zh-CN': require('./lang/cn'), // 通过require引入中文语言包
        'en-US': require('./lang/en') // 通过require引入英文语言包
    }
})

export default i18n