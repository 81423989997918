import { defineComponent, ref, computed } from "vue";
import useRouterHooks from "@/hooks/useRouterHooks";
import useI18nHooks from "@/hooks/useI18nHooks";
import store from "@/store";
import i18n from "@/assets/i18n/index";
export default defineComponent({
  name: "simpleHeader",

  setup() {
    //监听页面语言是否为中文
    const isZhCN = computed(() => {
      return i18n.global.locale === "zh-CN";
    }); //是否是手机

    const isMobile = store.state.isMobile; //页面跳转方法导入

    const {
      handleHomeRouter,
      handleAboutCoachRouter,
      handleUserStoryRouter,
      handleEnterpriseBuyRouter,
      handleJoinUsRouter,
      handleAboutUsRouter
    } = useRouterHooks(); //特殊的路由跳转

    const routerPush = function (to) {
      // this.$router.push(to);
      switch (to) {
        case "/AboutCoach":
          handleAboutCoachRouter();
          break;

        case "/UserStory":
          handleUserStoryRouter();
          break;

        case "/EnterpriseBuy":
          handleEnterpriseBuyRouter();
          break;

        case "/AboutUs":
          handleAboutUsRouter();
          break;

        case "/JoinUs":
          handleJoinUsRouter();
          break;

        default:
          break;
      }

      if (isMobile) {
        showMenu.value = false;
        store.state.isLight = false;
      }
    }; //切换语言方法导入


    const {
      changeLanguage
    } = useI18nHooks(); //是否展示手机端菜单

    const showMenu = ref(false); //是否高亮

    const showLight = computed(() => {
      return store.state.isLight && !showMenu.value;
    }); //展开menu

    const openMenu = function () {
      showMenu.value = true;
    }; //关闭menu


    const closeMenu = function () {
      showMenu.value = false;
    };

    return {
      isZhCN,
      handleHomeRouter,
      routerPush,
      changeLanguage,
      showMenu,
      isMobile,
      showLight,
      openMenu,
      closeMenu
    };
  }

});