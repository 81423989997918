export const m = {
  aboutCoach: "Coach Introduction",
  userStory: "User Story",
  enterpriseBuy: "Corporation Service",
  aboutUs: "About Us",
  joinUs: "Join Us",
  haveATry: "Reserve Online Class",
  watchVideo: "Watch Full Video",
  slogan: "Let’s Battle and Play",
  tagList: {
    realPersonEdu: "Certified Coaches",
    twoWay: "Live Instruciton",
    AIGame: "AI Games",
    pelletRefining: "Battle with Friends",
  },
  scanHint: "Scan WeChat QR code\nReserve Online Class",
  classHint: "Reserve Online Class",
  channel: {
    wx: "Scan to Follow Our Wechat Official Account",
    douyin: "Scan to Follow Our Douyin Official Account",
  },
  close: "Close",
  changeLang: "切换到中文",
};
