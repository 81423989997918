export const m = {
  aboutCoach: "教练风采",
  userStory: "用户口碑",
  enterpriseBuy: "企业服务",
  aboutUs: "关于我们",
  joinUs: "加入我们",
  haveATry: "体验在线私教课",
  watchVideo: "观看完整视频",
  slogan: "只要开始，就能坚持",
  tagList: {
    realPersonEdu: "真人私教",
    twoWay: "双向互动",
    AIGame: "AI游戏",
    pelletRefining: "小团精炼",
  },

  scanHint: "扫码 BodyPark 小程序\n体验在线真人私教课",
  classHint: "体验在线真人私教课",
  channel: {
    wx: "扫码关注官方公众号",
    douyin: "扫码关注官方抖音",
  },
  close: "关闭",
  changeLang: "Switch to English",
};
