import { defineComponent } from "vue";
export default defineComponent({
  name: "modal",
  props: {
    show: {
      type: Boolean,
      default: false
    },
    // TODO: 不知道为啥这里写成本地相对路径就不对
    qrCodeUrl: {
      type: String,
      default: "https://bpk-bucket-prod.oss-cn-beijing.aliyuncs.com/static/website/qr-wechat.png"
    },
    hint: {
      type: String,
      default: "@BodyPark型动公园"
    },
    subHint: {
      type: String,
      default: "扫码关注官方公众号"
    }
  },
  emits: ["hideModal"],

  setup(props, ctx) {
    const hideModal = function () {
      ctx.emit("hideModal");
    };

    return {
      hideModal
    };
  }

});