import { defineComponent, ref, onMounted, computed, onBeforeMount, onBeforeUnmount, reactive, watch } from 'vue';
import 'vant/es/toast/style';
import store from '@/store';
import { Field, Toast } from 'vant';
import { saveHomePhone } from '@/api/index';
export default defineComponent({
  name: 'App',

  setup(props, ctx) {
    const tel = ref('');
    const list = ref([{
      name: '燃脂塑形',
      val: '1',
      active: false
    }, {
      name: '体态改善',
      val: '2',
      active: false
    }, {
      name: '力量增肌',
      val: '3',
      active: false
    }, {
      name: '养成运动习惯',
      val: '4',
      active: false
    }]);

    const handleActive = index => {
      list.value[index].active = !list.value[index].active;
    };

    const canSubmit = computed(() => {
      const _list = list.value.filter(item => item.active);

      return tel.value.length === 11 && _list.length > 0;
    });

    const handleSubmit = async () => {
      if (!canSubmit) {
        return Toast.fail('请先输入手机号和选择需求');
      }

      if (!/^[1][3,6,4,5,7,8,9][0-9]{9}$/.test(tel.value)) {
        return Toast.fail('手机号错误');
      }

      list.value.filter(item => item.name);
      const {
        response,
        isFinished
      } = await saveHomePhone({
        phoneNumber: tel.value,
        objective: list.value.reduce((prev, next) => {
          if (next.active) {
            prev.push(next.name);
          }

          return prev;
        }, [])
      });

      if (isFinished.value && response.value.resCode === 1) {
        Toast.success('提交成功');
      }
    };

    onBeforeMount(() => {
      store.commit('setShowBar', false);
    });
    return {
      tel,
      list,
      handleActive,
      canSubmit,
      handleSubmit
    };
  },

  components: {
    Field
  }
});