import { createStore } from 'vuex'

export default createStore({
  state: {
    isMobile: false,
    isLight: false,
    showBar: true,
  },
  getters: {
  },
  mutations: {
    setMobile(state, isMobile) {
      state.isMobile = isMobile
    },
    setMobileNavLight(state, isLight) {
      state.isLight = isLight
    },
    setShowBar(state, showBar) {
      state.showBar = showBar
    }
  },
  actions: {
  },
  modules: {
  }
})
