import useAxiosApi from '@/utils/useAxiosApi';

/**
 *
 * @returns UseAxiosReturn
 */
export function saveHomePhone(
  { phoneNumber, objective } = { phoneNumber: '', objective: [] }
) {
  return useAxiosApi(`/fitlive/hera/customer/updateInclinedUserInfo`, {
    method: 'POST',
    data: {
      phoneNumber,
      objective,
    },
  });
}
