import { defineComponent, onBeforeMount, computed } from "vue";
import simpleHeader from "./components/simple-header.vue";
import simpleFooter from "./components/simple-footer.vue";
import store from "@/store";
export default defineComponent({
  name: "App",

  setup() {
    //是否展示页眉和页脚
    const isShowBar = computed(() => {
      return store.state.showBar;
    });
    onBeforeMount(() => {
      let isMobile = false;

      if (navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|IEMobile)/i)) {
        isMobile = true;
      }

      if (isMobile) {
        store.commit("setMobile", isMobile);
      }
    });
    return {
      isShowBar
    };
  },

  components: {
    simpleHeader,
    simpleFooter
  }
});