import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-61d36dfe"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "modal-bg"
};
const _hoisted_2 = {
  class: "modal-container"
};
const _hoisted_3 = ["src"];
const _hoisted_4 = {
  class: "modal-main"
};
const _hoisted_5 = {
  class: "hint"
};
const _hoisted_6 = {
  class: "sub-hint"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("img", {
    class: "modal-header",
    src: _ctx.qrCodeUrl
  }, null, 8, _hoisted_3), _createElementVNode("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.hint), 1), _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.subHint), 1)]), _createElementVNode("div", {
    class: "modal-footer",
    onClick: _cache[0] || (_cache[0] = (...args) => _ctx.hideModal && _ctx.hideModal(...args))
  }, _toDisplayString(_ctx.$t("m.close")), 1)])], 512)), [[_vShow, _ctx.show]]);
}