import { defineComponent, computed } from "vue";
import { useRoute } from "vue-router";
export default defineComponent({
  name: "footer",

  setup() {
    let route = useRoute();
    console.log(route);
    const routerQuery = computed(() => {
      const query = {
        check: false
      };

      if (route.query.check === "true") {
        query.check = true;
      }

      return query;
    });
    return {
      routerQuery
    };
  }

});