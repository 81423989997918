import { defineComponent, ref, onBeforeMount, computed, onUnmounted, reactive, watch } from "vue";
import store from "@/store";
export default defineComponent({
  name: "Video",

  setup() {
    onBeforeMount(() => {
      store.commit("setShowBar", false);
    });
    onUnmounted(() => {
      store.commit("setShowBar", true);
    });
  }

});