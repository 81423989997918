import i18n from "@/assets/i18n/index";

export default function () {
    const changeLanguage = function (lang: any) {
        if (lang) {
            i18n.global.locale = lang;
        } else {
            if (i18n.global.locale === "zh-CN") {
                i18n.global.locale = "en-US";
            } else if (i18n.global.locale === "en-US") {
                i18n.global.locale = "zh-CN";
            }
        }
    };
    return {
        changeLanguage

    }
}