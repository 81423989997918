import { defineComponent, ref, onMounted, computed, onBeforeUnmount, reactive, watch } from "vue";
import store from "@/store";
import Modal from "@/components/home/modal.vue";
import MobileModal from "@/components/home/mobile-modal.vue";
import useRouterHooks from "@/hooks/useRouterHooks";
import i18n from "@/assets/i18n/index";
import globalCss from "@/assets/js/constant";

const __default__ = defineComponent({
  name: "App",

  setup(props, ctx) {
    const showFullVideo = ref(false); //路由跳转方法引入

    const handleVideoRouter = function () {
      showFullVideo.value = true;
      store.state.showBar = false;
    };

    const closeFullVideo = function () {
      showFullVideo.value = false;
      store.state.showBar = true;
    }; //前往指定网站


    const gotoHLink = function (e) {
      window.open(e);
    }; //是否展示弹窗


    const show = ref(false);

    const hideModal = function () {
      // 取消弹窗回调
      show.value = false;
    }; //是否是手机


    const isMobile = store.state.isMobile; //qrcode地址

    const qrCodeUrl = ref("https://bpk-bucket-prod.oss-cn-beijing.aliyuncs.com/static/website/qr-wechat.png"); // 小米的video自动播放有问题

    const isNotXiaoMi = ref(!navigator.userAgent.match(/(XiaoMi | RedMi)/i));
    const subHint = ref("扫码关注官方公众号"); // 记录当前的滚动距离

    const scrollTop = ref(0);
    const disableScrollWatchUntil = reactive({
      data: null
    });
    const windowInnerHeight = ref(window.innerHeight);
    const windowHeight = computed(() => {
      return window.innerHeight + "px";
    });

    const scrollYFunction = function () {
      scrollTop.value = window.scrollY;
    };

    const scrollToMPCode = function () {
      scrollToWithCallback(window.innerHeight);
    };

    const scrollToWithCallback = function (scrollTopTargetValue) {
      disableScrollWatchUntil.data = scrollTopTargetValue;
      window.scrollTo({
        top: scrollTopTargetValue,
        behavior: "smooth"
      });
    }; //展示抖音或者微信小程序码


    const showQRCode = function (e) {
      show.value = true;

      if (e === "wechat") {
        qrCodeUrl.value = "https://bpk-bucket-prod.oss-cn-beijing.aliyuncs.com/static/website/qr-wechat.png";
        subHint.value = i18n.t("m.channel.wx");
      } else if (e === "douyin") {
        qrCodeUrl.value = "https://bpk-bucket-prod.oss-cn-beijing.aliyuncs.com/static/website/qr-douyin.png";
        subHint.value = i18n.t("m.channel.douyin");
      }
    };

    watch(scrollTop, (newValue, oldValue) => {
      let navHeight = globalCss.mobileNavHeight;
      const navLightThres = Number(navHeight.substr(0, navHeight.length - 2));

      if (oldValue <= navLightThres && newValue > navLightThres) {
        // 变dark
        store.commit("setMobileNavLight", false);
      } else if (newValue <= navLightThres && oldValue > navLightThres) {
        // 变light
        store.commit("setMobileNavLight", true);
      } // 点击按钮触发的自动滑动


      if (disableScrollWatchUntil.data) {
        if (newValue === disableScrollWatchUntil.data) {
          disableScrollWatchUntil.data = null;
        }

        return;
      }
    });
    onMounted(() => {
      if (isMobile) {
        store.commit("setMobileNavLight", true);
        window.addEventListener("scroll", scrollYFunction);
      }
    });
    onBeforeUnmount(() => {
      if (isMobile) {
        window.removeEventListener("scroll", scrollYFunction); //离开当前组件别忘记移除事件监听哦
      }
    });
    return {
      show,
      showFullVideo,
      qrCodeUrl,
      isMobile,
      isNotXiaoMi,
      subHint,
      handleVideoRouter,
      closeFullVideo,
      hideModal,
      gotoHLink,
      scrollToWithCallback,
      scrollTop,
      showQRCode,
      windowInnerHeight,
      scrollToMPCode,
      windowHeight
    };
  },

  components: {
    Modal,
    MobileModal
  }
});

import { useCssVars as _useCssVars } from 'vue';

const __injectCSSVars__ = () => {
  _useCssVars(_ctx => ({
    "00030748": _ctx.windowHeight
  }));
};

const __setup__ = __default__.setup;
__default__.setup = __setup__ ? (props, ctx) => {
  __injectCSSVars__();

  return __setup__(props, ctx);
} : __injectCSSVars__;
export default __default__;