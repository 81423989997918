import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import home from '@/views/home/index.vue';
import video from '@/views/video/index.vue';
import aboutUs from '@/views/aboutUs/index.vue';
import joinUs from '@/views/joinUs/index.vue';
import mobileHome from '@/views/h5-home/index.vue';
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/home',
  },
  {
    path: '/home',
    name: 'home',
    component: home,
  },
  {
    path: '/video',
    name: 'video',
    component: video,
  },
  {
    path: '/aboutUs',
    name: 'aboutUs',
    component: aboutUs,
  },
  {
    path: '/joinUs',
    name: 'joinUs',
    component: joinUs,
  },
  {
    path: '/mobile-home',
    name: 'mobileHome',
    component: mobileHome,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
