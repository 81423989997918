//这个js是为了，当js中无法使用constant.less中样式值时，能够通过导入该js获取到
const navFootZIdx = 10000;
const desktopNavHeight = "60px";
const desktopFootHeight = "198px";
const mobileNavHeight = "48px";
const mobileFootHeight = "96px";

// 导出变量
export default {
  desktopNavHeight,
  mobileNavHeight,
  desktopFootHeight,
  mobileFootHeight,
  navFootZIdx,
};
